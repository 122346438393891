import React, { Component } from 'react'
import isNode from 'is-node'
import { inject, observer } from 'mobx-react'
import images from 'src/json/images.json'

type Props = {
  src: string
  alt: string
  className?: any
  size?: string | string[]
  ui?: any
}

const folder = '/images'

const getImage = (src: string) => {
  return images.filter((image: any) => {
    return image.url === src
  })[0]
}

const sizesArray = ['0', '767px', '1023', '1099', '1499']

const getSizes = (size: string | string[]) => {
  if (typeof size === 'string') {
    return size
  } else {
    const sizeString = sizesArray.map((breakpoint: string, index: number) => {
      return `(max-width: ${breakpoint}) ${
        size[index] ? size[index] : size[size.length - 1]
      }`
    })
    sizeString.push(size[size.length - 1])
    return sizeString.join(', ')
  }
}

const getSrcSet = (files: any, isWebp: boolean, type: string): string => {
  const srcString = files.map((file: any) => {
    if (isWebp) {
      return `${folder}${file.file.replace(`.${type}`, '.webp')} ${file.size}w`
    }
    return `${folder}${file.file} ${file.size}w`
  })
  return srcString.join(', ')
}

const getSrc = (files: any, isWebp: boolean, type: string): string => {
  let file = ''
  if (files.length > 2) {
    file = `${folder}${files[2].file}`
  } else {
    file = `${folder}${files[files.length - 1].file}`
  }
  return isWebp ? file.replace(`.${type}`, '.webp') : file
}

@inject('ui')
@observer
export default class Image extends Component<Props> {
  render() {
    let { src, ui, alt, className, size = '100vw' } = this.props

    const image = getImage(src)
    if (!image) {
      return null
    }
    if (image.type === 'svg' || image.type === 'gif') {
      return (
        <img
          className={className}
          src={`${folder}${image.files[0].file}`}
          alt={alt}
        />
      )
    }

    src = getSrc(image.files, false, image.type)
    const sizes = getSizes(size)

    return isNode ? (
      <noscript>
        <img
          className={className}
          sizes={sizes}
          srcSet={getSrcSet(image.files, false, image.type)}
          src={src}
          alt={alt}
        />
      </noscript>
    ) : ui.webpChecked ? (
      ui.webpAccepted ? (
        <img
          className={className}
          sizes={sizes}
          srcSet={getSrcSet(image.files, true, image.type)}
          src={getSrc(image.files, true, image.type)}
          alt={alt}
        />
      ) : (
        <img
          className={className}
          sizes={sizes}
          srcSet={getSrcSet(image.files, false, image.type)}
          src={src}
          alt={alt}
        />
      )
    ) : null
  }
}