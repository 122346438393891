import * as React from 'react'
import { Link } from 'gatsby'
import { observer } from 'mobx-react'
import Image from '../components/UI/Image'
import entries from '../json/entries.json'


type Props = {
  props: any
}

type TemplateProps = {
  pageContext: any
}

@observer
export default class Index extends React.Component<TemplateProps> {
  render() {
    return (
      <div>
        <h1
          style={{
            fontFamily: 'monospace',
            fontSize: 20,
            textAlign: 'center',
            margin: 100,
          }}
        >
          Coming soon!
        </h1>
        <p style={{ fontFamily: 'monospace', textAlign: 'center' }}>ecom.love promotes beautiful and functional e-commerce websites.</p>
      </div>
    )
    return (
      <div>
        {entries.map((entry: any) => (
          <Link to={`/${entry.slug}`}>
            {entry.screenshots.map((screenshot: any) => (
              <Image
                src={screenshot.fields.file.url}
                alt={screenshot.fields.title}
                size={'50vw'}
              />
            ))}
          </Link>
        ))}
      </div>
    )
  }
}